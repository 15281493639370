<template>
  <el-dialog
    :title="type === 'create' ? '创建仓库' : '编辑仓库'"
    :visible.sync="visible"
    :modal-append-to-body="false"
    :before-close="cancel"
    width="600px"
  >
    <field label="仓库名称：" :asterisk="true" labelWidth="121px" :validation="validation.name"   >
      <el-input v-model="formData.name" placeholder="请输入仓库名称"></el-input>
    </field>
    <field label="发货人信息：" :asterisk="true" labelWidth="121px" :validation="validation.senderName"   >
      <div class="half">
        <el-input v-model="formData.senderName" placeholder="请输入姓名"></el-input>
        <el-input v-model="formData.senderMobile" type="tel" placeholder="请输入电话" maxlength="11"></el-input>
      </div>
    </field>
    <field label="发货人地址：" :asterisk="true" labelWidth="121px" :validation="validation.address" style="padding-bottom: 10px;">
      <div class="three">
        <el-select v-model="formData.province" placeholder="请选择省份" @change="handleProvniceChange">
          <el-option
            v-for="item in provinces"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select v-model="formData.city" placeholder="请选择城市" @change="handleCityChange">
          <el-option
            v-for="item in cities"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select v-model="formData.country" placeholder="请选择区">
          <el-option
            v-for="item in countries"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-input v-model="formData.address" placeholder="请输入地址"></el-input>
      </div>
    </field>
    <field label="小票打印机：" :asterisk="true" labelWidth="121px" :validation="validation.receiptMachineCode"   >
        <el-input v-model="formData.receiptMachineCode" placeholder="请输入终端号"></el-input>
    </field>
    <field label="面单打印机：" :asterisk="true" labelWidth="121px" :validation="validation.interfaceMachineCode"   >
        <el-input v-model="formData.interfaceMachineCode" placeholder="请输入终端号"></el-input>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as commonService from '@/api/common'
import * as R from 'ramda'
const init = {
  name: '',
  senderName: '',
  senderMobile: '',
  address: '',
  province: null,
  city: null,
  country: null,
  clientId: 'clientId',
  clientSecret: 'clientSecret',
  // 面单打印机终端号
  receiptMachineCode: '',
  // 面单打印机终端号
  interfaceMachineCode: ''
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    type: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  created () {
    this.fetchProvinces()
  },
  data () {
    return {
      formData: R.clone(init),
      provinces: [],
      cities: [],
      countries: []
    }
  },
  mixins: [validation({
    rules: {
      name: [v.required('请输入仓库名称')],
      senderName: [
        v.required('请输入发货人姓名'),
        v.assertObj((val, row) => row.senderMobile !== '', '请输入电话号码')
      ],
      address: [
        v.required('请输入地址'),
        v.assertObj((val, row) => !R.isNil(row.province), '请选择省份'),
        v.assertObj((val, row) => !R.isNil(row.city), '请选择城市'),
        v.assertObj((val, row) => !R.isNil(row.country), '请选择区')
      ],
      receiptMachineCode: [v.required('请输入小票终端号')],
      interfaceMachineCode: [v.required('请输入面单终端号')]
    },
    field: 'formData'
  })],
  methods: {
    async fetchProvinces () {
      const res = await commonService.fetchProvinces()
      this.provinces = res.list
    },
    async handleProvniceChange (data) {
      const res = await commonService.fetchCities(data)
      this.cities = res.list
      this.countries = []
    },
    async handleCityChange (data) {
      const res = await commonService.fetchCountries(this.formData.province, data)
      this.countries = res.list
    },
    async fetchCountries (p, c) {
      const res = await commonService.fetchCountries(p, c)
      this.countries = res.list
    },
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    },
    async confirm () {
      try {
        await this.$validate()
        const province = this.provinces.find(o => o.code === this.formData.province)
        const city = this.cities.find(o => o.code === this.formData.city)
        const country = this.countries.find(o => o.code === this.formData.country)
        const postData = {
          name: this.formData.name,
          address: this.formData.address,
          senderName: this.formData.senderName,
          senderMobile: this.formData.senderMobile,
          provinceCode: province.code.toString(),
          provinceName: province.name,
          cityCode: city.code.toString(),
          cityName: city.name,
          expAreaName: country.name,
          expAreaCode: country.code.toString(),
          receiptMachineCode: this.formData.receiptMachineCode,
          interfaceMachineCode: this.formData.interfaceMachineCode,
          clientId: this.formData.clientId,
          clientSecret: this.formData.clientSecret
        }
        if (this.type === 'create') {
          this.$emit('onConfirmCreate', postData)
        } else {
          this.$emit('onConfirmEdit', postData)
        }
        this.formData = R.clone(init)
      } catch (err) {
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler: async function (curVal, oldVal) {
        await this.handleProvniceChange(curVal.provinceCode)
        await this.fetchCountries(curVal.provinceCode, curVal.cityCode)
        this.formData = {
          name: curVal.name,
          userList: curVal.userList,
          senderName: curVal.senderName,
          senderMobile: curVal.senderMobile,
          address: curVal.address,
          province: parseInt(curVal.provinceCode),
          city: parseInt(curVal.cityCode),
          country: parseInt(curVal.expAreaCode),
          receiptMachineCode: curVal.receiptMachineCode,
          interfaceMachineCode: curVal.interfaceMachineCode,
          clientId: curVal.clientId,
          clientSecret: curVal.clientSecret
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

.half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 11px;
  margin-bottom: 0px;
}

.three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 11px;
  margin-bottom: 10px;
}

</style>
