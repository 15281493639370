<template>
  <div class="container">
    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-button type="danger" plain>删除</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="warehouses"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="仓库名称"> </el-table-column>
        <el-table-column prop="type" label="发件人信息">
          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.senderName }}</div>
              <div>{{ scope.row.senderMobile }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="发货人地址">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.provinceName +
                  scope.row.cityName +
                  scope.row.expAreaName
              }}
            </p>
            <p>{{ scope.row.address }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="创建时间">
          <template slot-scope="scope">
            {{ fmtDate(scope.row.createdDate) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button
              v-if="!scope.row.goodsNum || scope.row.goodsNum === 0"
              type="text"
              @click="deleteOne(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EditContent
      :visible="showEdit"
      :type="type"
      :data="selectData"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="showEdit = false"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'
import * as warehouseService from '@/api/warehouse'
import EditContent from './edit'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      search: '',
      warehouses: [],
      selected: [],
      type: 'create',
      showEdit: false,
      selectData: null
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    async fetchData () {
      const res = await warehouseService.list()
      this.warehouses = res
    },
    handleCreate () {
      this.type = 'create'
      this.selectData = null
      this.showEdit = true
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    edit (data) {
      this.type = 'edit'
      this.selectData = R.clone(data)
      this.showEdit = true
    },
    async deleteOne (id) {
      try {
        await warehouseService.deleteById(id)
        this.fetchData()
        this.$notify.success({ title: '删除成功！' })
      } catch (err) {
        this.$notify.error({ title: '删除失败！' })
      }
    },
    async handleConfirmEdit (data) {
      data.id = this.selectData.id
      try {
        await warehouseService.update(this.selectData.id, data)
        this.fetchData()
        this.$notify.success({ title: '修改成功' })
      } catch (err) {
        this.$notify.error({ title: '修改失败' })
      } finally {
        this.showEdit = false
      }
    },
    async handleConfirmCreate (data) {
      try {
        await warehouseService.create(data)
        this.$notify.error('创建成功！')
        this.fetchData()
        this.showEdit = false
      } catch (err) {
        this.$notify.error('创建失败！')
      }
    },
    async batchDelete () {
      await Promise.all(this.selected.map(o => this.deleteOne(o.id)))
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}
</style>
